//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { SetStateAction, useCallback, useEffect, useState } from "react";

// Material ui Imports
import { Grid } from "@mui/material";
// Custom components imports (and hooks and helpers)
import {
    useGetAllScenarioOverviewQuery,
    useGetScenarioQuery,
    useUpdateScenarioMutation,
} from "../../services/scenario.service";
import { useGetAllGroupsQuery, useGetAllUsersQuery } from "../../services/users.service";
import {
    useGetAll360ImagesQuery,
    useGetAll360VideosQuery,
    useGetAllAudioQuery,
    useGetAllImagesQuery,
    useGetAllVideos2dQuery,
    useUpdateAllImagesMutation,
    useUpdateAllScenarioMediaMutation,
} from "../../services/media.service";
import { useGetLanguageQuery } from "../../services/language.service";
import { Params, useNavigate, useParams } from "react-router-dom";
import CreatorTabsPanelContainer from "../containers/CreatorTabsPanelContainer";
import CreatorCanvasContainer from "../containers/CreatorCanvasContainer";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
    setCurrentAttachedHotspot,
    setCurrentAudio,
    setCurrentDragging,
    setCurrentHotspot,
    setCurrentLookAt,
    setCurrentQuestion,
    setCurrentScene,
    setCurrentSidePanel,
    setCurrentToast,
    setCurrentlyPreview,
    setHotspotAdded,
} from "../../store/slices/current.slice";

import { IDragOptions } from "../../models/current.models";
import { blankHotspot, blankQuestionHotspot } from "../../helpers/hotspotDataGenerator";
import { EMedia } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { blankScene } from "../../helpers/scenarioDataGenerator";
import { v4 as uuid } from "uuid";
import { EDragContentOptions } from "../../models/hotspotDrag.models";
import { IScenario, IScene, IStep } from "../../models/scenario.models";
import { EHotspotType, IHotspot } from "../../models/hotspot.models";
import { Vector3, cloneUniformsGroups } from "three";
import VTHotpsot from "../components/VTHotspot";
import VTHotspotEditDialog from "../components/VTHotspotEditDialog";
import CreatorFeedbackContainer from "../containers/CreatorFeedbackContainer";
import { idText } from "typescript";
import { EOrganisation } from "../../models/organisations.model";
import ReactGA from "react-ga4";
// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
// Optional variables towards the bottom

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
export default function CreatorContentWrapper() {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const { scenarioId, sceneId }: Readonly<Params<string>> = useParams();
    const { id: userId, organisation } = useAppSelector((state) => state.userReducer);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##
    const { data, isLoading, error } = useGetAllScenarioOverviewQuery(userId);
    const { data: scenario, isLoading: scenarioDataLoading } = useGetScenarioQuery(scenarioId ? scenarioId : "");
    const users = useGetAllUsersQuery();
    const groups = useGetAllGroupsQuery(userId);

    const language = useGetLanguageQuery();
    const { currentScene } = useAppSelector((state) => state.currentReducer);
    const { currentHotspot, currentQuestion, currentSidePanel, currentlyDragging } = useAppSelector(
        (state) => state.currentReducer,
    );
    const { mediaUploading, mediaUploadCompleted } = useAppSelector((state) => state.mediaUploadReducer);

    const [currentAttachedObject, setCurrentAttachedObject] = useState<any>(undefined);
    const [changeSceneBuffer, setChangeSceneBuffer] = useState<string | undefined>(undefined);
    const [videoCurrentState, setVideoCurrentState] = useState<"Paused" | "Playing" | "Question" | undefined>(
        undefined,
    );
    const [updateAllImages] = useUpdateAllImagesMutation();
    const [updateAllScenarioMedia] = useUpdateAllScenarioMediaMutation();
    const [updateScenario, { isLoading: scenarioLoading }] = useUpdateScenarioMutation();

    useEffect(() => {
        if (scenarioId) {
            ReactGA.send({ hitType: "pageview", page: `/creator/${scenarioId}`, title: scenarioId });
        }
    }, [scenarioId]);

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##

    useEffect(() => {
        if (scenario && scenario.scenes.length === 0) {
            if (organisation === EOrganisation.ECO_GOAL) {
                handleMediaTimelineDropped({
                    mediaType: EDragContentOptions.DOCUMENT,
                    src: "",
                });
            } else {
                handleMediaTimelineDropped({
                    mediaType: EDragContentOptions.IMAGE_360,
                    src: "https://medias3.digi-sim-api.co.uk/app_defaults/default_360_image",
                });
            }
        }

        // dispatch(setCurrentHotspot({ currentHotspot: undefined, open: false }));
        if (!sceneId && scenario && scenario.scenes.length) {
            // If no scene is provided in the url load first scene in the array
            navigate(`/creator/${scenarioId}/${scenario.scenes[0].id}`);

            dispatch(setCurrentScene(scenario.scenes[0]));
        } else if (scenario) {
            // If sceneId was provided find scene and set current scene
            const foundScene: IScene | undefined = scenario?.scenes.find((scene: IScene) => scene.id === sceneId);

            if (foundScene) {
                navigate(`/creator/${scenarioId}/${foundScene.id}`);

                dispatch(setCurrentScene(foundScene));
            } else {
                // If the sceneId could no be found load first scene
                if (scenario.scenes && scenario.scenes[0] && scenario.scenes[0].id) {
                    navigate(`/creator/${scenarioId}/${scenario.scenes[0].id}`);
                    dispatch(setCurrentScene(scenario.scenes[0]));
                }
            }
        }
        if (scenario && changeSceneBuffer && scenario.scenes.find((scene: IScene) => scene.id === changeSceneBuffer)) {
            navigate(`/creator/${scenarioId}/${changeSceneBuffer}`);
            setChangeSceneBuffer(undefined);
        }
    }, [sceneId, scenario]);
    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##

    useEffect(() => {
        dispatch(setCurrentHotspot({ currentHotspot: undefined, open: false }));

        if (scenario) {
            const foundScene: IScene | undefined = scenario?.scenes.find((scene: IScene) => scene.id === sceneId);

            foundScene && foundScene.audio && dispatch(setCurrentAudio(foundScene.audio));
        }
    }, [sceneId]);

    useEffect(() => {
        dispatch(
            setCurrentSidePanel({
                currentTab: undefined,
                width: 0,
                option: undefined,
            }),
        );
    }, []);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    // Function to handle media dragged from the side panel onto the timeline
    function handleMediaTimelineDropped(timelineMedia: IDragOptions) {
        // TODO; Add new scene
        const newSceneId = uuid();

        ReactGA.send({
            hitType: "timelineDrop",
            page: "/creator",
            title: `${timelineMedia.mediaType} added to timeline`,
        });

        dispatch(setCurrentlyPreview(false));

        if (scenario && timelineMedia.mediaType === EDragContentOptions.VIDEO_360) {
            const newScenario: IScenario = {
                ...scenario,
                scenes: [
                    ...scenario.scenes,
                    blankScene(timelineMedia.src, EMedia.VIDEO_360, timelineMedia.preview, newSceneId),
                ],
            };

            updateScenario(newScenario);

            updateAllScenarioMedia({
                id: scenarioId!,
                media_type: EMedia.VIDEO_360,
                media_ids: [timelineMedia.src.split("three_sixty_video/")[1].split(".")[0].split("/")[0]],
            });
        } else if (scenario && timelineMedia.mediaType === EDragContentOptions.IMAGE_360) {
            const newScenario: IScenario = {
                ...scenario,
                scenes: [
                    ...scenario.scenes,
                    blankScene(timelineMedia.src, EMedia.IMAGE_360, timelineMedia.preview, newSceneId),
                ],
            };

            updateScenario(newScenario);

            if (timelineMedia.src !== "https://medias3.digi-sim-api.co.uk/app_defaults/default_360_image") {
                updateAllScenarioMedia({
                    id: scenarioId!,
                    media_type: EMedia.IMAGE_360,
                    media_ids: [timelineMedia.src.split("three_sixty_image/")[1].split(".")[0]],
                });
            }
        } else if (scenario && timelineMedia.mediaType === EDragContentOptions.VIDEO) {
            const newScenario: IScenario = {
                ...scenario,
                scenes: [
                    ...scenario.scenes,
                    blankScene(timelineMedia.src, EMedia.VIDEO, timelineMedia.preview, newSceneId),
                ],
            };

            updateScenario(newScenario);
            updateAllScenarioMedia({
                id: scenarioId!,
                media_type: EMedia.VIDEO,
                media_ids: [timelineMedia.src.split("video/")[1].split(".")[0]],
            });
        } else if (timelineMedia.mediaType === EDragContentOptions.MODEL_3D_BASE) {
            if (scenario) {
                const newScenario: IScenario = {
                    ...scenario,
                    scenes: [...scenario.scenes, blankScene(timelineMedia.src, EMedia.MODEL_3D, timelineMedia.preview)],
                };

                updateScenario(newScenario);
            }
        } else if (timelineMedia.mediaType === EDragContentOptions.DOCUMENT) {
            if (scenario) {
                const newScenario: IScenario = {
                    ...scenario,
                    scenes: [...scenario.scenes, blankScene("", EMedia.DOCUMENT, timelineMedia.preview)],
                };

                updateScenario(newScenario);
            }
        } else if (timelineMedia.mediaType === EDragContentOptions.PASSTHROUGH) {
            if (scenario) {
                const newScenario: IScenario = {
                    ...scenario,
                    scenes: [...scenario.scenes, blankScene("", EMedia.PASSTHROUGH, timelineMedia.preview)],
                };

                updateScenario(newScenario);
            }
        }
        setChangeSceneBuffer(newSceneId);
    }

    // Function to handle media dragged from the side panel onto the canvas
    function handleMediaCanvasDropped(canvasMedia: IDragOptions) {
        dispatch(
            setCurrentHotspot({
                currentHotspot: undefined,
                open: false,
            }),
        );
        dispatch(setCurrentlyPreview(false));
        ReactGA.send({ hitType: "canvasDrop", page: "/creator", title: `${canvasMedia.mediaType} added to canvas` });

        if (canvasMedia.mediaType === EDragContentOptions.IMAGE_360) {
            if (scenario) {
                const newScenario: IScenario = {
                    ...scenario,
                    scenes: scenario.scenes.map((element: IScene) =>
                        currentScene.id === element.id
                            ? {
                                  ...element,
                                  src: canvasMedia.src,
                                  mediaType:
                                      currentScene.mediaType === EMedia.PASSTHROUGH
                                          ? EMedia.PASSTHROUGH
                                          : EMedia.IMAGE_360,
                                  hotspots: currentScene.hotspots.filter(
                                      (hotspot: IHotspot) => hotspot.type !== EHotspotType.QUESTION,
                                  ),

                                  previewImage: canvasMedia.preview,
                              }
                            : element,
                    ),
                };

                updateScenario(newScenario);
                updateAllScenarioMedia({
                    id: scenarioId!,
                    media_type: EMedia.IMAGE_360,
                    media_ids: [
                        canvasMedia.src.includes("three_sixty_image")
                            ? canvasMedia.src.split("three_sixty_image/")[1].split(".")[0]
                            : canvasMedia.src.split("app_defaults/")[1].split(".")[0],
                    ],
                });
            }
        } else if (canvasMedia.mediaType === EDragContentOptions.VIDEO_360) {
            if (scenario) {
                if (currentScene.mediaType === EMedia.VIDEO_360) {
                    dispatch(
                        setCurrentToast({
                            id: "VideoReplace",
                            message: "New Video, Stop points might not be in the right location",
                        }),
                    );
                }
                const newScenario: IScenario = {
                    ...scenario,
                    scenes: scenario.scenes.map((element: IScene) =>
                        currentScene.id === element.id
                            ? {
                                  ...element,
                                  src: canvasMedia.src,
                                  mediaType: EMedia.VIDEO_360,
                                  hotspots: currentScene.hotspots,

                                  previewImage: canvasMedia.preview,
                              }
                            : element,
                    ),
                };

                updateScenario(newScenario);

                updateAllScenarioMedia({
                    id: scenarioId!,
                    media_type: EMedia.VIDEO_360,
                    media_ids: [canvasMedia.src.split("three_sixty_video/")[1].split(".")[0].split("/")[0]],
                });
            }
        } else if (canvasMedia.mediaType === EDragContentOptions.AUDIO) {
            if (scenario) {
                const newScenario = {
                    ...scenario,
                    scenes: [
                        ...scenario.scenes.map((scene: IScene) =>
                            scene.id === sceneId
                                ? {
                                      ...scene,
                                      audio: canvasMedia.src,
                                  }
                                : scene,
                        ),
                    ],
                };

                updateScenario(newScenario);
                updateAllScenarioMedia({
                    id: scenarioId!,
                    media_type: EMedia.AUDIO,
                    media_ids: [canvasMedia.src.split("audio/")[1].split(".")[0]],
                });
            }
        } else if (
            canvasMedia.mediaType === EDragContentOptions.INFORMATION ||
            canvasMedia.mediaType === EDragContentOptions.MOVEMENT ||
            canvasMedia.mediaType === EDragContentOptions.MOVEMENT_3D ||
            canvasMedia.mediaType === EDragContentOptions.VIDEO ||
            canvasMedia.mediaType === EDragContentOptions.ANSWER ||
            canvasMedia.mediaType === EDragContentOptions.INTERFACE ||
            canvasMedia.mediaType === EDragContentOptions.IMAGE ||
            canvasMedia.mediaType === EDragContentOptions.IMMERSIVE_MODEL ||
            canvasMedia.mediaType === EDragContentOptions.COMPLETE
        ) {
            if (scenario) {
                const newHotspot = blankHotspot(canvasMedia.mediaType, canvasMedia.src);
                const newScenario = {
                    ...scenario,
                    scenes: [
                        ...scenario.scenes.map((scene: IScene) =>
                            scene.id === sceneId
                                ? {
                                      ...scene,
                                      hotspots: [...scene.hotspots, newHotspot],
                                  }
                                : scene,
                        ),
                    ],
                };

                updateScenario(newScenario);
                dispatch(setCurrentAttachedHotspot(newHotspot));
                dispatch(setHotspotAdded(newHotspot));
                dispatch(setCurrentHotspot({ currentHotspot: newHotspot, open: false }));

                if (canvasMedia.mediaType === EDragContentOptions.IMAGE) {
                    updateAllScenarioMedia({
                        id: scenarioId!,
                        media_type: EMedia.IMAGE,
                        media_ids: [canvasMedia.src.split("images/")[1].split(".")[0]],
                    });
                } else if (canvasMedia.mediaType === EDragContentOptions.VIDEO) {
                    updateAllScenarioMedia({
                        id: scenarioId!,
                        media_type: EMedia.VIDEO,
                        media_ids: [canvasMedia.src.split("video/")[1].split(".")[0]],
                    });
                }
            }
        } else if (canvasMedia.mediaType === EDragContentOptions.MODEL_3D_BASE) {
            if (scenario) {
                const newScenario: IScenario = {
                    ...scenario,
                    scenes: scenario.scenes.map((element: IScene) =>
                        currentScene.id === element.id
                            ? {
                                  ...element,
                                  src: canvasMedia.src,
                                  mediaType: EMedia.MODEL_3D,
                                  hotspots: [],
                                  previewImage: canvasMedia.preview,
                              }
                            : element,
                    ),
                };

                updateScenario(newScenario);
            }
        } else if (canvasMedia.mediaType === EDragContentOptions.DOCUMENT) {
            if (scenario) {
                const newScenario: IScenario = {
                    ...scenario,
                    scenes: scenario.scenes.map((element: IScene) =>
                        currentScene.id === element.id
                            ? {
                                  ...element,
                                  src: "",
                                  mediaType: EMedia.DOCUMENT,
                                  hotspots: [],
                                  previewImage: canvasMedia.preview,
                              }
                            : element,
                    ),
                };

                updateScenario(newScenario);
            }
        } else if (canvasMedia.mediaType === EDragContentOptions.PASSTHROUGH) {
            if (scenario) {
                const newScenario: IScenario = {
                    ...scenario,
                    scenes: scenario.scenes.map((element: IScene) =>
                        currentScene.id === element.id
                            ? {
                                  ...element,
                                  src: currentScene.mediaType === EMedia.IMAGE_360 ? currentScene.src : "",
                                  mediaType: EMedia.PASSTHROUGH,
                                  hotspots: currentScene.hotspots.filter(
                                      (hotspot: IHotspot) => hotspot.type !== EHotspotType.QUESTION,
                                  ),

                                  previewImage: canvasMedia.preview,
                              }
                            : element,
                    ),
                };

                updateScenario(newScenario);
            }
        }
    }

    function handleMediaQuestionCanvasDropped(canvasMedia: IDragOptions, hotspotId?: string) {
        dispatch(
            setCurrentHotspot({
                currentHotspot: undefined,
                open: false,
            }),
        );
        dispatch(setCurrentlyPreview(false));
        ReactGA.send({
            hitType: "questionDrop",
            page: "/creator",
            title: `${canvasMedia.mediaType} added to question`,
        });

        if (canvasMedia.mediaType === EDragContentOptions.IMAGE_360) {
            if (scenario) {
                const newScenario: IScenario = {
                    ...scenario,
                    scenes: [
                        ...scenario.scenes.filter((element: any) => currentScene.id !== element.id),
                        {
                            id: currentScene.id,
                            name: currentScene.name,
                            src: canvasMedia.src,
                            mediaType: EMedia.IMAGE_360,
                            hotspots: currentScene.hotspots.filter(
                                (hotspot: IHotspot) => hotspot.type !== EHotspotType.QUESTION,
                            ),
                            context: {},
                            startLocation: [0, 0, 0],
                            previewImage: canvasMedia.preview,
                        },
                    ],
                };

                updateScenario(newScenario);
                updateAllScenarioMedia({
                    id: scenarioId!,
                    media_type: EMedia.IMAGE_360,
                    media_ids: [
                        canvasMedia.src.includes("three_sixty_image")
                            ? canvasMedia.src.split("three_sixty_image/")[1].split(".")[0]
                            : canvasMedia.src.split("app_defaults/")[1].split(".")[0],
                    ],
                });
            }
        } else if (canvasMedia.mediaType === EDragContentOptions.VIDEO_360) {
            if (scenario) {
                const newScenario: IScenario = {
                    ...scenario,
                    scenes: [
                        ...scenario.scenes.filter((element: any) => currentScene.id !== element.id),
                        {
                            id: currentScene.id,
                            name: currentScene.name,
                            src: canvasMedia.src,
                            mediaType: EMedia.VIDEO_360,
                            hotspots: currentScene.hotspots.filter(
                                (hotspot: IHotspot) => hotspot.type !== EHotspotType.QUESTION,
                            ),
                            context: {},
                            startLocation: [0, 0, 0],
                            previewImage: canvasMedia.preview,
                        },
                    ],
                };

                updateScenario(newScenario);
                updateAllScenarioMedia({
                    id: scenarioId!,
                    media_type: EMedia.VIDEO_360,
                    media_ids: [canvasMedia.src.split("three_sixty_video/")[1].split(".")[0].split("/")[0]],
                });
            }
        } else if (canvasMedia.mediaType === EDragContentOptions.MODEL_3D_BASE) {
            if (scenario) {
                const newScenario: IScenario = {
                    ...scenario,
                    scenes: scenario.scenes.map((element: IScene) =>
                        currentScene.id === element.id
                            ? {
                                  ...element,
                                  src: canvasMedia.src,
                                  mediaType: EMedia.MODEL_3D,
                                  hotspots: [],
                                  previewImage: canvasMedia.preview,
                              }
                            : element,
                    ),
                };

                updateScenario(newScenario);
            }
        } else if (canvasMedia.mediaType === EDragContentOptions.DOCUMENT) {
            if (scenario) {
                const newScenario: IScenario = {
                    ...scenario,
                    scenes: scenario.scenes.map((element: IScene) =>
                        currentScene.id === element.id
                            ? {
                                  ...element,
                                  src: "",
                                  mediaType: EMedia.DOCUMENT,
                                  hotspots: [],
                                  previewImage: canvasMedia.preview,
                              }
                            : element,
                    ),
                };

                updateScenario(newScenario);
            }
        } else if (canvasMedia.mediaType === EDragContentOptions.PASSTHROUGH) {
            if (scenario) {
                const newScenario: IScenario = {
                    ...scenario,
                    scenes: scenario.scenes.map((element: IScene) =>
                        currentScene.id === element.id
                            ? {
                                  ...element,
                                  src: "",
                                  mediaType: EMedia.PASSTHROUGH,
                                  hotspots: [],
                                  previewImage: canvasMedia.preview,
                              }
                            : element,
                    ),
                };

                updateScenario(newScenario);
            }
        } else if (canvasMedia.mediaType === EDragContentOptions.AUDIO) {
            if (scenario) {
                const newScenario = {
                    ...scenario,
                    scenes: [
                        ...scenario.scenes.map((scene: IScene) =>
                            scene.id === sceneId
                                ? {
                                      ...scene,
                                      hotspots: [
                                          ...scene.hotspots.map((hotspot: IHotspot) => {
                                              if (hotspot.id === hotspotId) {
                                                  return {
                                                      ...hotspot,
                                                      contents: {
                                                          preClickContent: {
                                                              ...hotspot.contents.preClickContent,
                                                              question: {
                                                                  ...hotspot.contents.preClickContent.question,
                                                                  audio: canvasMedia.src,
                                                              },
                                                          },
                                                      },
                                                  };
                                              } else {
                                                  return hotspot;
                                              }
                                          }),
                                      ],
                                  }
                                : scene,
                        ),
                    ],
                };
                //@ts-ignore
                updateScenario(newScenario);
                updateAllScenarioMedia({
                    id: scenarioId!,
                    media_type: EMedia.AUDIO,
                    media_ids: [canvasMedia.src.split("audio/")[1].split(".")[0]],
                });
            }
        } else {
            if (scenario) {
                const newHotspot = blankHotspot(canvasMedia.mediaType, canvasMedia.src);
                const newScenario = {
                    ...scenario,
                    scenes: [
                        ...scenario.scenes.map((scene: IScene) =>
                            scene.id === sceneId
                                ? {
                                      ...scene,
                                      hotspots: [
                                          ...scene.hotspots.map((hotspot: IHotspot) => {
                                              if (hotspot.id === hotspotId) {
                                                  return {
                                                      ...hotspot,
                                                      contents: {
                                                          preClickContent: {
                                                              ...hotspot.contents.preClickContent,
                                                              question: {
                                                                  ...hotspot.contents.preClickContent.question,
                                                                  answers: [
                                                                      ...hotspot.contents.preClickContent.question
                                                                          ?.answers!,
                                                                      newHotspot,
                                                                  ],
                                                              },
                                                          },
                                                      },
                                                  };
                                              } else {
                                                  return hotspot;
                                              }
                                          }),
                                      ],
                                  }
                                : scene,
                        ),
                    ],
                };
                //@ts-ignore
                updateScenario(newScenario);
                dispatch(setCurrentAttachedHotspot(newHotspot));
                dispatch(setHotspotAdded(newHotspot));

                if (canvasMedia.mediaType === EDragContentOptions.IMAGE) {
                    updateAllScenarioMedia({
                        id: scenarioId!,
                        media_type: EMedia.IMAGE,
                        media_ids: [canvasMedia.src.split("images/")[1].split(".")[0]],
                    });
                } else if (canvasMedia.mediaType === EDragContentOptions.VIDEO) {
                    updateAllScenarioMedia({
                        id: scenarioId!,
                        media_type: EMedia.VIDEO,
                        media_ids: [canvasMedia.src.split("video/")[1].split(".")[0]],
                    });
                }
            }
        }
    }

    // Function to handle media dragged from the side panel onto the canvas
    function handleSceneDelete(sceneId: string) {
        ReactGA.send({ hitType: "sceneDelete", page: "/creator", title: `${sceneId} scene deleted` });
        if (scenario) {
            const newScenario: IScenario = {
                ...scenario,
                scenes: scenario.scenes.filter((element: IScene) => element.id !== sceneId),
            };

            updateScenario(newScenario);
        }
    }

    // Function to handle media dragged from the side panel onto the canvas
    function handleAudioDelete(sceneId: string, questionId?: string) {
        if (scenario) {
            if (questionId) {
                const newScenario = {
                    ...scenario,
                    scenes: [
                        ...scenario.scenes.map((scene: IScene) =>
                            scene.id === sceneId
                                ? {
                                      ...scene,
                                      hotspots: [
                                          ...scene.hotspots.map((hotspot: IHotspot) => {
                                              if (hotspot.id === questionId) {
                                                  return {
                                                      ...hotspot,
                                                      contents: {
                                                          preClickContent: {
                                                              ...hotspot.contents.preClickContent,
                                                              question: {
                                                                  ...hotspot.contents.preClickContent.question,
                                                                  audio: undefined,
                                                              },
                                                          },
                                                      },
                                                  };
                                              } else {
                                                  return hotspot;
                                              }
                                          }),
                                      ],
                                  }
                                : scene,
                        ),
                    ],
                };
                dispatch(setCurrentAudio(""));
                //@ts-ignore
                updateScenario(newScenario);
            } else {
                const newScenario = {
                    ...scenario,
                    scenes: [
                        ...scenario.scenes.map((scene: IScene) =>
                            scene.id === sceneId
                                ? {
                                      ...scene,
                                      audio: undefined,
                                  }
                                : scene,
                        ),
                    ],
                };

                dispatch(setCurrentAudio(""));
                updateScenario(newScenario);
            }
        }
    }

    function handleSceneDuplicate(sceneId: string) {
        ReactGA.send({ hitType: "sceneDuplicate", page: "/creator", title: `${sceneId} scene duplicated` });
        if (scenario) {
            const sceneToDuplicate = scenario.scenes.find((element: IScene) => element.id === sceneId);
            const newSceneId = uuid();
            const newScenario: IScenario = {
                ...scenario,
                scenes: [
                    ...scenario.scenes,
                    {
                        id: newSceneId,
                        name: sceneToDuplicate!.name + " - copy",
                        src: sceneToDuplicate!.src,
                        mediaType: sceneToDuplicate!.mediaType,
                        hotspots: sceneToDuplicate!.hotspots,
                        context: sceneToDuplicate!.context,
                        startLocation: sceneToDuplicate!.startLocation,
                        previewImage: sceneToDuplicate!.previewImage,
                        audio: sceneToDuplicate!.audio,
                    },
                ],
            };

            updateScenario(newScenario);
            setChangeSceneBuffer(newSceneId);
        }
    }

    function handleSceneNameUpdate(sceneId: string, sceneName: string) {
        if (scenario) {
            const newScenario: IScenario = {
                ...scenario,
                scenes: [
                    ...scenario.scenes.map((scene: IScene) =>
                        scene.id === sceneId ? { ...scene, name: sceneName } : scene,
                    ),
                ],
            };

            updateScenario(newScenario);
        }
    }

    function handleSceneSetInitialView(sceneId: string, newPosition: any) {
        if (scenario) {
            const newScenario: IScenario = {
                ...scenario,
                scenes: [
                    ...scenario.scenes.map((scene: IScene) =>
                        scene.id === sceneId
                            ? {
                                  ...scene,
                                  startLocation: [
                                      newPosition.x.toFixed(5),
                                      newPosition.y.toFixed(5),
                                      newPosition.z.toFixed(5),
                                  ],
                              }
                            : scene,
                    ),
                ],
            };

            updateScenario(newScenario);
        }
    }

    function handleAddSceneToStepper(sceneId: string, value: boolean) {
        ReactGA.send({ hitType: "sceneAdded", page: "/creator", title: `${sceneId} scene added` });

        if (scenario) {
            const sceneToDuplicate = scenario.scenes.find((element: IScene) => element.id === sceneId);
            const newScenario: IScenario = {
                ...scenario,
                scenes: [
                    ...scenario.scenes.map((scene: IScene) =>
                        scene.id === sceneId ? { ...scene, stepper: value } : scene,
                    ),
                ],
            };

            updateScenario(newScenario);
        }
    }

    function handleSceneReorder(sceneNewIndex: number) {
        if (scenario) {
            const sceneToDuplicateChange = scenario.scenes.find(
                (element: IScene) => element.id === currentlyDragging?.src,
            );
            const sceneToDuplicateIndex = scenario.scenes.findIndex(
                (element: IScene) => element.id === currentlyDragging?.src,
            );
            let newSceneList: IScene[] = [];

            scenario.scenes.map((scene: IScene, index: number) => {
                if (index === sceneNewIndex) {
                    newSceneList.push(sceneToDuplicateChange!);
                    newSceneList.push(scene);
                } else {
                    newSceneList.push(scene);
                }
            });
            if (scenario.scenes.length === sceneNewIndex) {
                newSceneList.push(sceneToDuplicateChange!);
            }

            if (sceneToDuplicateIndex > sceneNewIndex) {
                newSceneList.splice(sceneToDuplicateIndex + 1, 1);
            } else {
                newSceneList.splice(sceneToDuplicateIndex, 1);
            }

            const newScenario: IScenario = {
                ...scenario,
                scenes: newSceneList,
            };

            dispatch(setCurrentDragging(undefined));
            updateScenario(newScenario);
        }
    }

    function handleAddStopPoint(
        sceneId: string,
        time: number,
        setVideoNextQuestion: (videoNextQuestion: IHotspot | undefined) => void,
    ) {
        const newQuestion = blankQuestionHotspot(time);
        setVideoNextQuestion(newQuestion);
        dispatch(setCurrentQuestion(newQuestion));

        ReactGA.send({ hitType: "stopPointAdded", page: "/creator", title: `${sceneId} stop point added` });

        if (scenario) {
            let newHotspotArray: IHotspot[] = [];
            let newQuestionArray: IHotspot[] = [];

            scenario.scenes
                .find((scene: IScene) => scene.id === sceneId)
                ?.hotspots.forEach((hotspot: IHotspot) => {
                    if (hotspot.type !== EHotspotType.QUESTION) {
                        newHotspotArray.push(hotspot);
                    } else {
                        newQuestionArray.push(hotspot);
                    }
                });

            newQuestionArray.push(newQuestion);

            newQuestionArray.sort(
                (a, b) => a.contents.preClickContent.question?.time! - b.contents.preClickContent.question?.time!,
            );

            const newScenario = {
                ...scenario,
                scenes: [
                    ...scenario.scenes.map((scene: IScene) => {
                        return scene.id === sceneId
                            ? {
                                  ...scene,
                                  hotspots: [...newHotspotArray, ...newQuestionArray],
                              }
                            : scene;
                    }),
                ],
            };

            updateScenario(newScenario);
        }
    }

    function handleDeleteStopPoint(sceneId: string, hotspotId: string) {
        ReactGA.send({ hitType: "stopPointDeleted", page: "/creator", title: `${sceneId} stop point deleted` });

        if (scenario) {
            const newScenario = {
                ...scenario,
                scenes: [
                    ...scenario.scenes.map((scene: IScene) =>
                        scene.id === sceneId
                            ? {
                                  ...scene,
                                  hotspots: scene.hotspots.filter((hotspot: IHotspot) => hotspot.id !== hotspotId),
                              }
                            : scene,
                    ),
                ],
            };

            //@ts-ignore
            updateScenario(newScenario);
        }
    }

    function handleSaveHotspotLocation(hotspotId: string, newPosition: Vector3, videoCurrentState: string | undefined) {
        if (scenario && scenario.scenes) {
            if (currentHotspot.currentHotspot && currentHotspot.currentHotspot.id === hotspotId) {
                dispatch(
                    setCurrentHotspot({
                        currentHotspot: { ...currentHotspot.currentHotspot, location: newPosition },
                        open: false,
                    }),
                );
            }

            if (videoCurrentState === "Question") {
                const newScenario = {
                    ...scenario,
                    scenes: [
                        ...scenario.scenes.map((scene: IScene) =>
                            scene.id === sceneId
                                ? {
                                      ...scene,
                                      hotspots: [
                                          ...scene.hotspots.map((hotspot: IHotspot) =>
                                              hotspot.id === currentQuestion.id
                                                  ? {
                                                        ...hotspot,
                                                        contents: {
                                                            ...hotspot.contents,
                                                            preClickContent: {
                                                                ...hotspot.contents.preClickContent,
                                                                question: {
                                                                    ...hotspot.contents.preClickContent.question!,
                                                                    answers:
                                                                        hotspot.contents.preClickContent.question!.answers.map(
                                                                            (answer: IHotspot) =>
                                                                                answer.id === hotspotId
                                                                                    ? {
                                                                                          ...answer,
                                                                                          location: newPosition,
                                                                                      }
                                                                                    : answer,
                                                                        ),
                                                                },
                                                            },
                                                        },
                                                    }
                                                  : hotspot,
                                          ),
                                      ],
                                  }
                                : scene,
                        ),
                    ],
                };

                updateScenario(newScenario);
                setCurrentAttachedObject(undefined);
            } else {
                const newScenario: IScenario = {
                    ...scenario,
                    scenes: [
                        ...scenario.scenes.map((scene: IScene) =>
                            scene.id === sceneId
                                ? {
                                      ...scene,
                                      hotspots: scene.hotspots.map((hotspot: IHotspot) =>
                                          hotspot.id === hotspotId
                                              ? {
                                                    ...hotspot,
                                                    location: newPosition,
                                                }
                                              : hotspot,
                                      ),
                                  }
                                : scene,
                        ),
                    ],
                };

                updateScenario(newScenario);
                setCurrentAttachedObject(undefined);
            }
        }
    }

    function handleDeleteHotspotLocation(hotspotId: string) {
        ReactGA.send({ hitType: "hotspotDeleted", page: "/creator", title: `${hotspotId} hotspot deleted` });
        if (scenario) {
            if (
                scenario.scenes
                    .find((e: IScene) => e.id === currentScene.id)
                    ?.hotspots.find((e: IHotspot) => e.id === currentQuestion.id)
                    ?.contents.preClickContent.question?.answers.find((e: IHotspot) => e.id === hotspotId)
            ) {
                const newScenario = {
                    ...scenario,
                    scenes: [
                        ...scenario.scenes.map((scene: IScene) =>
                            scene.id === sceneId
                                ? {
                                      ...scene,
                                      hotspots: [
                                          ...scene.hotspots.map((hotspot: IHotspot) =>
                                              hotspot.id === currentQuestion.id
                                                  ? {
                                                        ...hotspot,
                                                        contents: {
                                                            ...hotspot.contents,
                                                            preClickContent: {
                                                                ...hotspot.contents.preClickContent,
                                                                question: {
                                                                    ...hotspot.contents.preClickContent.question!,
                                                                    answers:
                                                                        hotspot.contents.preClickContent.question!.answers.filter(
                                                                            (answer: IHotspot) =>
                                                                                answer.id !== hotspotId,
                                                                        ),
                                                                },
                                                            },
                                                        },
                                                    }
                                                  : hotspot,
                                          ),
                                      ],
                                  }
                                : scene,
                        ),
                    ],
                };

                updateScenario(newScenario);
            } else {
                const newScenario: IScenario = {
                    ...scenario,
                    scenes: [
                        ...scenario.scenes.map((scene: IScene) =>
                            scene.id === sceneId
                                ? {
                                      ...scene,
                                      hotspots: scene.hotspots.filter((hotspot: IHotspot) => hotspot.id !== hotspotId),
                                  }
                                : scene,
                        ),
                    ],
                };

                updateScenario(newScenario);
            }
            if (currentHotspot && currentHotspot.currentHotspot && currentHotspot.currentHotspot.id === hotspotId) {
                dispatch(setCurrentHotspot({ currentHotspot: undefined, open: false }));
            }
        }
    }

    function handleUpdateInterface(hotspotId: string, newInterface: IHotspot) {
        if (scenario) {
            if (
                scenario.scenes
                    .find((e: IScene) => e.id === currentScene.id)
                    ?.hotspots.find((e: IHotspot) => e.id === currentQuestion.id)
                    ?.contents.preClickContent.question?.answers.find((e: IHotspot) => e.id === hotspotId)
            ) {
                const newScenario = {
                    ...scenario,
                    scenes: [
                        ...scenario.scenes.map((scene: IScene) =>
                            scene.id === sceneId
                                ? {
                                      ...scene,
                                      hotspots: [
                                          ...scene.hotspots.map((hotspot: IHotspot) =>
                                              hotspot.id === currentQuestion.id
                                                  ? {
                                                        ...hotspot,
                                                        contents: {
                                                            ...hotspot.contents,
                                                            preClickContent: {
                                                                ...hotspot.contents.preClickContent,
                                                                question: {
                                                                    ...hotspot.contents.preClickContent.question!,
                                                                    answers:
                                                                        hotspot.contents.preClickContent.question!.answers.map(
                                                                            (answer: IHotspot) =>
                                                                                answer.id === hotspotId
                                                                                    ? newInterface
                                                                                    : answer,
                                                                        ),
                                                                },
                                                            },
                                                        },
                                                    }
                                                  : hotspot,
                                          ),
                                      ],
                                  }
                                : scene,
                        ),
                    ],
                };

                updateScenario(newScenario);
            } else {
                const newScenario: IScenario = {
                    ...scenario,
                    scenes: [
                        ...scenario.scenes.map((scene: IScene) =>
                            scene.id === sceneId
                                ? {
                                      ...scene,
                                      hotspots: scene.hotspots.map((hotspot: IHotspot) =>
                                          hotspot.id === hotspotId ? newInterface : hotspot,
                                      ),
                                  }
                                : scene,
                        ),
                    ],
                };

                updateScenario(newScenario);
            }
        }
    }

    function handleEditQuestionTitle(questionId: string, newTitle: string) {
        if (scenario) {
            const newScenario = {
                ...scenario,
                scenes: [
                    ...scenario.scenes.map((scene: IScene) =>
                        scene.id === sceneId
                            ? {
                                  ...scene,
                                  hotspots: [
                                      ...scene.hotspots.map((hotspot: IHotspot) =>
                                          hotspot.id === questionId
                                              ? {
                                                    ...hotspot,
                                                    contents: {
                                                        ...hotspot.contents,
                                                        preClickContent: {
                                                            ...hotspot.contents.preClickContent,
                                                            title: newTitle,
                                                        },
                                                    },
                                                }
                                              : hotspot,
                                      ),
                                  ],
                              }
                            : scene,
                    ),
                ],
            };

            updateScenario(newScenario);
        }
    }

    function handleEditQuestionTimer(questionId: string, newTimer: number) {
        if (scenario) {
            const newScenario = {
                ...scenario,
                scenes: [
                    ...scenario.scenes.map((scene: IScene) =>
                        scene.id === sceneId
                            ? {
                                  ...scene,
                                  hotspots: [
                                      ...scene.hotspots.map((hotspot: IHotspot) =>
                                          hotspot.id === questionId
                                              ? {
                                                    ...hotspot,
                                                    contents: {
                                                        ...hotspot.contents,
                                                        preClickContent: {
                                                            ...hotspot.contents.preClickContent,
                                                            question: {
                                                                ...hotspot.contents.preClickContent.question,
                                                                countdown: newTimer,
                                                            },
                                                        },
                                                    },
                                                }
                                              : hotspot,
                                      ),
                                  ],
                              }
                            : scene,
                    ),
                ],
            };

            //@ts-ignore
            updateScenario(newScenario);
        }
    }
    console.log("why???");
    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // call relevant data from api and get from store
    return (
        <>
            <Grid container xs={12} justifyContent={"flex-end"}>
                {/*-----------------------------------------------------------------------------------------*/}
                {/* Left Side panel */}
                <CreatorTabsPanelContainer
                    handleCanvasDrop={handleMediaCanvasDropped}
                    handleQuestionCanvasDrop={handleMediaQuestionCanvasDropped}
                    handleTimelineDrop={handleMediaTimelineDropped}
                    videoCurrentState={videoCurrentState}
                    setVideoCurrentState={setVideoCurrentState}
                />

                {/*-----------------------------------------------------------------------------------------*/}
                {/* Main page content */}
                <CreatorCanvasContainer
                    scenarioLoading={scenarioDataLoading}
                    scene={currentScene}
                    handleMediaCanvasDropped={handleMediaCanvasDropped}
                    handleMediaQuestionCanvasDropped={handleMediaQuestionCanvasDropped}
                    handleAddStopPoint={handleAddStopPoint}
                    handleDeleteStopPoint={handleDeleteStopPoint}
                    isLoading={scenarioDataLoading || scenarioLoading || isLoading}
                    handleSaveHotspotLocation={handleSaveHotspotLocation}
                    handleDeleteHotspotLocation={handleDeleteHotspotLocation}
                    handleAudioDelete={handleAudioDelete}
                    handleEditQuestionTitle={handleEditQuestionTitle}
                    handleEditQuestionTimer={handleEditQuestionTimer}
                    scenario={scenario ? scenario : ({} as IScenario)}
                    updateScenario={updateScenario}
                    currentAttachedObject={currentAttachedObject}
                    setCurrentAttachedObject={setCurrentAttachedObject}
                    handleMediaTimelineDropped={handleMediaTimelineDropped}
                    setSceneName={handleSceneNameUpdate}
                    setSceneDelete={handleSceneDelete}
                    setSceneDuplicate={handleSceneDuplicate}
                    setSceneInitialView={handleSceneSetInitialView}
                    setSceneStepper={handleAddSceneToStepper}
                    setSceneReorder={handleSceneReorder}
                    handleUpdateInterface={handleUpdateInterface}
                    videoCurrentState={videoCurrentState}
                    setVideoCurrentState={setVideoCurrentState}
                />

                <CreatorFeedbackContainer />
            </Grid>
        </>
    );
}
