import {
    CancelRounded,
    CloseRounded,
    ContentCopyRounded,
    CreateRounded,
    DeleteRounded,
    Image,
    SaveRounded,
    Visibility,
    VisibilityRounded,
    HdrWeakRounded,
} from "@mui/icons-material";
import { Grid, styled, SxProps, Typography, Box, Tooltip } from "@mui/material";
import { MouseEventHandler, useState, useEffect, SetStateAction } from "react";
import LoopIcon from "@mui/icons-material/Loop";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Vector3 } from "three";
import { IScene } from "../../models/scenario.models";
import { VTButton, VTMenu, VTTextField } from "@virtus-tech-repository/virtus-tech-repository";
import { EMedia } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import VTLocation from "./VTLocation";
import { useDispatch } from "react-redux";
import { setCurrentDragging, setCurrentStartingLocation } from "../../store/slices/current.slice";
import { EDragContentOptions } from "../../models/hotspotDrag.models";
import { setCurrentSidePanel } from "../../store/slices/current.slice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import NoPreview from "../../assets/images/NoScenarioImage.svg";
import NoPreviewFile from "../../assets/images/NoFilesImage.svg";
import PassthroughPreview from "../../assets/images/PassthroughPreview.svg";
import Model3DPreview from "../../assets/images/Model3dPreview.svg";

export const TimelineImageWrapper = styled(Grid)(({ theme }) => ({
    borderRadius: "15px",
    height: "100px",
    overflow: "hidden",
    position: "relative",
    transition: ".5s",
    ":hover": {
        cursor: "pointer",
        boxShadow: "0 0 25px #2ad5c7",
    },
}));

export enum MediaType {
    VIDEO,
    PICTURE,
}

export interface ITimelineImageItem {
    titleCutOffLimit?: number;
    sx?: SxProps;
    active?: boolean;
    size?: number;
    setSceneName: (sceneId: string, sceneName: string) => void;
    setSceneDuplicate: (sceneId: string) => void;
    setSceneDelete: (sceneId: string) => void;
    setSceneInitialView: (sceneId: string, newPosition: Vector3) => void;
    setSceneStepper: (sceneId: string, value: boolean) => void;
    onClicked?: () => void;
    menuOffSet?: { height: number; width: number };
    currentScene: IScene;
}

//Now use a useState to change the colour of the border radius (from grey to lime green).
//Ask whether the option menu should have any actions (drop down menu)

function getMediaTypeImageStyleObject(size: number): {} {
    return size > 115 ? { height: "50px", width: "50px" } : { height: "25px", width: "25px" };
}

export default function VTTimelineImageItem({
    titleCutOffLimit,
    sx,
    active,
    size = 150,
    currentScene,
    setSceneName,
    setSceneDuplicate,
    setSceneDelete,
    setSceneInitialView,
    setSceneStepper,
    onClicked,
    menuOffSet = { height: 270, width: 120 },
}: ITimelineImageItem) {
    const dispatch = useDispatch();
    const { organisation } = useAppSelector((state) => state.userReducer);

    const [isHovered, setIsHovered] = useState(false);
    const [initialViewDialog, setInitialViewDialog] = useState(false);
    const { id, name, previewImage, mediaType, src } = currentScene;
    const [localSceneName, setLocalSceneName] = useState<string>(name);
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [imageError, setImageError] = useState<boolean>(false);

    useEffect(() => {
        setLocalSceneName(name);
    }, [name]);

    function textCutOffAddElipsis(text: string, cutOffPointCharIndex: number) {
        const charAtIndex = text.charAt(cutOffPointCharIndex);
        if (charAtIndex === " ") {
            return text.substring(0, cutOffPointCharIndex) + "...";
        } else if (charAtIndex === "") {
            return text;
        } else {
            return text.substring(0, cutOffPointCharIndex + 1) + "...";
        }
    }

    function handlePreivewImage(): string {
        switch (mediaType) {
            case EMedia.IMAGE_360:
                return `${currentScene.src}/f.jpg`;
            case EMedia.VIDEO_360:
                return currentScene.src.split(".mp4")[0] + "_preview.png";
            default:
                return "https://virtus-tech-media-api-test.s3.eu-west-1.amazonaws.com/app_defaults/no_image.png";
        }
    }

    return (
        <>
            <Grid container sx={{ width: size, ...sx, position: "relative" }}>
                <VTMenu
                    popOutMenuZIndex={1000}
                    sx={{
                        width: "30px",
                        height: "30px",
                        position: "absolute",
                        ...(size > 115 ? { top: "5px", right: "5px" } : { top: "2px", right: "2px" }),
                        zIndex: 2,
                        "& .MuiButtonBase-root": {
                            background: "rgb(0, 0, 0, 0.6)",
                        },
                    }}
                    type="icon"
                    color="secondary"
                    onCloseFunction={() => {
                        setIsHovered(false);
                        setMenuOpen(false);
                    }}
                    direction={"UP"}
                    offsetWidth={menuOffSet.width}
                    offsetHeight={menuOffSet.height}
                    menuOpen={menuOpen}
                    setMenuOpen={() => setMenuOpen}
                    onClick={() => setMenuOpen(!menuOpen)}
                    menuOptions={[
                        {
                            component: (
                                <form
                                    onSubmit={(e) => {
                                        setSceneName(id, localSceneName);
                                    }}
                                >
                                    <VTTextField
                                        placeholder="Scene Name"
                                        value={localSceneName}
                                        endIcon={
                                            <>
                                                {localSceneName !== name && (
                                                    <>
                                                        <CloseRounded
                                                            onClick={(e) => {
                                                                setLocalSceneName(name);
                                                            }}
                                                            sx={{
                                                                cursor: "pointer",
                                                            }}
                                                            color={"error"}
                                                        />
                                                        <SaveRounded
                                                            onClick={(e) => {
                                                                setSceneName(id, localSceneName);
                                                            }}
                                                            sx={{
                                                                cursor: "pointer",
                                                                marginLeft: "5px",
                                                            }}
                                                            color={"success"}
                                                        />
                                                    </>
                                                )}
                                            </>
                                        }
                                        onChange={(e) => setLocalSceneName(e)}
                                        sx={{ marginInline: "5px" }}
                                    />
                                </form>
                            ),
                        },
                        {
                            component: (
                                <VTButton
                                    type={"list"}
                                    startIcon={<ContentCopyRounded />}
                                    disabled={setSceneDuplicate === undefined}
                                    onClick={() => setSceneDuplicate!(id)}
                                >
                                    Duplicate
                                </VTButton>
                            ),
                        },
                        {
                            component: (
                                <VTButton
                                    type={"list"}
                                    startIcon={<DeleteRounded />}
                                    disabled={setSceneDelete === undefined}
                                    onClick={() => setSceneDelete!(id)}
                                >
                                    {" "}
                                    Delete
                                </VTButton>
                            ),
                        },
                        {
                            component: (
                                <VTButton
                                    type={"list"}
                                    startIcon={<VisibilityRounded />}
                                    disabled={setSceneInitialView === undefined || mediaType === EMedia.VIDEO}
                                    onClick={() => {
                                        setMenuOpen(false);
                                        dispatch(
                                            setCurrentSidePanel({
                                                currentTab: undefined,
                                                width: 0,
                                                option: "DISABLED",
                                            }),
                                        );
                                        setInitialViewDialog(true);
                                    }}
                                >
                                    Set Initial View
                                </VTButton>
                            ),
                        },
                        {
                            component: (
                                <VTButton
                                    type={"list"}
                                    startIcon={currentScene.stepper ? <CancelRounded /> : <HdrWeakRounded />}
                                    onClick={() => {
                                        setMenuOpen(false);
                                        if (currentScene.stepper) {
                                            setSceneStepper(id, false);
                                        } else {
                                            setSceneStepper(id, true);
                                        }
                                    }}
                                >
                                    {currentScene.stepper ? "Remove from Stepper" : "Add to Stepper"}
                                </VTButton>
                            ),
                        },
                    ]}
                >
                    <MoreHorizIcon sx={{ color: "white" }} />
                </VTMenu>
                <TimelineImageWrapper
                    container
                    item
                    sx={{
                        background: active ? "linear-gradient(to top left, #0ab5a7,#8e25fa)" : "none",
                        padding: "4px",
                        width: size,
                        height: size * 0.75,
                    }}
                    xs={12}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={onClicked}
                >
                    {imageError ||
                    mediaType === EMedia.DOCUMENT ||
                    mediaType === EMedia.PASSTHROUGH ||
                    mediaType === EMedia.MODEL_3D ? (
                        <img
                            style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "15px",
                                position: "absolute",
                                top: 0,
                                left: 0,
                                padding: "4px",
                                userSelect: "none",
                                MozUserSelect: "none",
                                WebkitUserSelect: "none",
                            }}
                            alt={"preview for media"}
                            src={
                                mediaType === EMedia.DOCUMENT
                                    ? NoPreviewFile
                                    : mediaType === EMedia.PASSTHROUGH
                                    ? PassthroughPreview
                                    : mediaType === EMedia.MODEL_3D
                                    ? Model3DPreview
                                    : NoPreview
                            }
                        ></img>
                    ) : (
                        <img
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "15px",

                                position: "absolute",
                                top: 0,
                                left: 0,
                                padding: "4px",
                                userSelect: "none",
                                MozUserSelect: "none",
                                WebkitUserSelect: "none",
                            }}
                            alt={"preview for media"}
                            src={handlePreivewImage()}
                            onError={() => setImageError(true)}
                        ></img>
                    )}

                    {isHovered && (
                        <Box
                            component="div"
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "absolute",
                                top: 0,
                                left: 0,
                                border: "linear-gradient(183deg, rgba(150,28,255,0.5) 0%, rgba(0,191,160,0.5) 100%)",
                                height: "100%",
                                width: "100%",
                                background:
                                    "linear-gradient(183deg, rgba(150,28,255,0.5) 0%, rgba(0,191,160,0.5) 100%)",
                                zIndex: 1,

                                userSelect: "none",
                            }}
                        >
                            {mediaType === EMedia.IMAGE_360 && (
                                <img
                                    src="/360-image.svg"
                                    draggable={true}
                                    style={getMediaTypeImageStyleObject(size)}
                                    alt={"360 preview"}
                                    onDragStart={() => {
                                        dispatch(
                                            setCurrentDragging({
                                                mediaType: EDragContentOptions.SCENE,
                                                src: currentScene.id,
                                            }),
                                        );
                                    }}
                                    onDragEnd={() => dispatch(setCurrentDragging(undefined))}
                                />
                            )}
                            {mediaType === EMedia.VIDEO_360 && (
                                <img
                                    src="/360-video.svg"
                                    alt={"360 video preview"}
                                    style={getMediaTypeImageStyleObject(size)}
                                    draggable={true}
                                    onDragStart={() =>
                                        dispatch(
                                            setCurrentDragging({
                                                mediaType: EDragContentOptions.SCENE,
                                                src: currentScene.id,
                                            }),
                                        )
                                    }
                                    onDragEnd={() => dispatch(setCurrentDragging(undefined))}
                                />
                            )}
                            {mediaType === EMedia.VIDEO && (
                                <img
                                    src="/video.svg"
                                    alt={"video preview"}
                                    style={getMediaTypeImageStyleObject(size)}
                                    draggable={true}
                                    onDragStart={() =>
                                        dispatch(
                                            setCurrentDragging({
                                                mediaType: EDragContentOptions.SCENE,
                                                src: currentScene.id,
                                            }),
                                        )
                                    }
                                    onDragEnd={() => dispatch(setCurrentDragging(undefined))}
                                />
                            )}
                        </Box>
                    )}
                </TimelineImageWrapper>
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "2px",
                        userSelect: "none",
                        MozUserSelect: "none",
                        WebkitUserSelect: "none",
                    }}
                >
                    {name && (
                        <Tooltip arrow title={titleCutOffLimit && name.length >= titleCutOffLimit && name}>
                            <Typography variant={size > 135 ? "body1" : "caption"}>
                                {titleCutOffLimit
                                    ? name && name.length <= titleCutOffLimit
                                        ? name
                                        : textCutOffAddElipsis(name, titleCutOffLimit)
                                    : name && name}
                            </Typography>
                        </Tooltip>
                    )}
                </Grid>
            </Grid>

            <VTLocation
                currentScene={currentScene}
                open={initialViewDialog}
                setOpen={() => {
                    setInitialViewDialog(!initialViewDialog);
                }}
                onSave={(id, newPosition) => {
                    setSceneInitialView(id, newPosition);
                    dispatch(setCurrentStartingLocation(true));
                }}
            />
        </>
    );
}
