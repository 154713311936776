/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 movement.glb -t
*/

import * as THREE from "three";
import { useEffect, useRef, useState, Suspense } from "react";
import { Html, PivotControls, useGLTF } from "@react-three/drei";
import VTHotspotControls from "@virtus-tech-repository/virtus-tech-repository/lib/components/VtHotspotControls";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../store/hooks";
import { IHotspot, EHotspotType } from "../../../models/hotspot.models";

interface IProps {
    hotspot: IHotspot;
}

export default function Model({ hotspot }: IProps) {
    const dispatch = useDispatch();
    const [moving, setMoving] = useState<boolean>(false);
    const [localPosition, setLocalPosition] = useState<THREE.Vector3>();
    const objectRef = useRef();
    //@ts-ignore
    const { scene } = useGLTF(hotspot.contents.preClickContent.contentData);

    const { currentlyPreview } = useAppSelector((state) => state.currentReducer);

    useEffect(() => {
        console.log("DBG: drag", localPosition);
    }, [localPosition]);

    useEffect(() => {
        console.log("DBG: load", hotspot);
    }, [hotspot]);

    return (
        <group position={[hotspot.location.x, hotspot.location.y, hotspot.location.z]}>
            <PivotControls
                disableAxes={!moving}
                disableRotations={!moving}
                disableSliders={!moving}
                scale={
                    hotspot.styles && hotspot.styles.preClickStyles && hotspot.styles.preClickStyles!.scale
                        ? hotspot.styles.preClickStyles.scale
                        : 1
                }
                onDrag={(l, dl, w, dw) => {
                    // Extract the position and rotation
                    const position = new THREE.Vector3();
                    const rotation = new THREE.Quaternion();
                    // I'm never sure whether to grab "l" or "w" here... sorry
                    w.decompose(position, rotation, new THREE.Vector3());
                    setLocalPosition(position);
                }}
            >
                <group dispose={null} scale={1}>
                    <Suspense fallback={null}>
                        <primitive
                            ref={objectRef}
                            object={scene}
                            scale={
                                hotspot.styles && hotspot.styles.preClickStyles && hotspot.styles.preClickStyles!.scale
                                    ? hotspot.styles.preClickStyles.scale
                                    : 1
                            }
                            position={[0, 0, 0]}
                        />
                    </Suspense>
                </group>
            </PivotControls>
        </group>
    );
}
