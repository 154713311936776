import { ContactShadows, Environment, GizmoHelper, GizmoViewport, Grid, OrbitControls } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { Avatar } from "./Avatar";
import SpeechToText from "./SpeechToText";
import TextToSpeech from "./TextToSpeech";
import CameraWireFrameHelper from "./CameraWireFrameHelper";
import { IHotspot, EHotspotType } from "../../../models/hotspot.models";
import ImmersiveModel from "../threeDModels.tsx/ImmersiveModel";
import Model from "./Model";

interface IProps {
    hotspots: IHotspot[];
}

export default function ThreeDViewer({ hotspots }: IProps) {
    return (
        <>
            <Canvas shadows camera={{ position: [0, 5, 5], fov: 30 }} style={{ backgroundColor: "#222326" }}>
                <OrbitControls />
                {/* <Sky /> */}
                <Grid args={[50, 50]} />
                <Environment preset="sunset" />
                <GizmoHelper
                    alignment="bottom-right" // widget alignment within scene
                    margin={[80, 80]} // widget margins (X, Y)
                >
                    <GizmoViewport axisColors={["red", "green", "blue"]} labelColor="black" />
                </GizmoHelper>

                <group position-y={0}>
                    <ContactShadows opacity={0.42} scale={10} blur={1} far={10} resolution={256} color="#000000" />
                    <Avatar />

                    <mesh scale={5} position={[0, 2, -5]}>
                        <planeGeometry args={[2, 0.8]} />
                        <meshStandardMaterial color="gray" />
                    </mesh>

                    <mesh scale={5} rotation-y={-Math.PI} position={[0, 2, 5]}>
                        <planeGeometry args={[2, 0.8]} />
                        <meshStandardMaterial color="gray" />
                    </mesh>

                    <mesh scale={5} rotation-y={Math.PI / 2} position={[-5, 2, 0]}>
                        <planeGeometry args={[2, 0.8]} />
                        <meshStandardMaterial color="gray" />
                    </mesh>

                    <mesh scale={5} rotation-y={-Math.PI * 0.5} position={[5, 2, 0]}>
                        <planeGeometry args={[2, 0.8]} />
                        <meshStandardMaterial color="gray" />
                    </mesh>

                    <mesh scale={5} rotation-x={-Math.PI * 0.5} position-y={-0.002}>
                        <planeGeometry args={[2, 2]} />
                        <meshStandardMaterial color="white" />
                    </mesh>

                    {hotspots &&
                        hotspots.map((hotspot: IHotspot, i: number) => (
                            <>{hotspot.type === EHotspotType.IMMERSIVE_MODEL && <Model hotspot={hotspot} />}</>
                        ))}
                </group>

                <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
                    <GizmoViewport axisColors={["#9d4b4b", "#2f7f4f", "#3b5b9d"]} labelColor="white" />
                </GizmoHelper>
                <CameraWireFrameHelper />
            </Canvas>
            {/* <SpeechToText />
            <TextToSpeech /> */}
        </>
    );
}
