import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import { pink } from "@mui/material/colors";
import { Suspense, useEffect, useRef, useState } from "react";
import { EMedia, IMediaItem } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import {
    Box,
    Breakpoint,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Grid,
    LinearProgress,
    SxProps,
    Typography,
} from "@mui/material";
import { SearchRounded } from "@mui/icons-material";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import NoAudios from "../../assets/images/NoAudios.svg";
import {
    VTAudioItem,
    VTButton,
    VTDialog,
    VTMenu,
    VTTextField,
    VTTypography,
    VTAudioPlayerCard,
    useGetMuiBreakPointLabel,
    VTCanvas,
    VTCard,
    useGetVTCardTitleCutOffPoint,
} from "@virtus-tech-repository/virtus-tech-repository";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import UploadIcon from "@mui/icons-material/Upload";
import { v4 as uuid } from "uuid";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
    useCreateImmersiveModelsMutation,
    useGetAllImmersiveModelsQuery,
    usePreSignedUrlMutation,
    useUpdateAllImmersiveModelsMutation,
} from "../../services/media.service";
import { setMediaUploadCleanUp } from "../../store/slices/mediaUpload.slice";
import { uploadMedia } from "../../store/thunks/upload.thunks";
import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useGLTF, Gltf } from "@react-three/drei";
import { ExposedCameraControlFunctions } from "@virtus-tech-repository/virtus-tech-repository/lib/components/VTCanvasComponents/VTCameraControls";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import NoVideos from "../../assets/images/NoVideos.svg";
import NoScenarios from "../../assets/images/NoScenarios.svg";
import { setCurrentToast } from "../../store/slices/current.slice";

interface ModelProps {
    url: string;
    cameraControlsRef: any;
}

function Model({ url, cameraControlsRef }: ModelProps) {
    const objectRef = useRef();
    const { scene } = useGLTF(url);

    useEffect(() => {
        if (cameraControlsRef && cameraControlsRef.current && objectRef && objectRef.current) {
            setTimeout(() => {
                cameraControlsRef.current.fitToObject(objectRef.current, true);
            }, 500);
        }
    }, [cameraControlsRef, objectRef]);

    return (
        <Suspense fallback={null}>
            <primitive ref={objectRef} object={scene} position={[0, 0, 0]} />
        </Suspense>
    );
}

interface IProps {
    leftDrawWidth: number;
    loading: boolean;
    deleteMediaItem: (mediaType: EMedia, collectionId: string, mediaId: string) => void;
    deleteLoading: boolean;
    models: IMediaItem[] | undefined;
}

export default function ImmersiveModelsCardContainer({
    leftDrawWidth,
    loading,
    deleteMediaItem,
    deleteLoading,
    models,
}: IProps) {
    // ## HOOKS ##
    const inputFileRef = useRef<HTMLInputElement>(null);
    const currentScreenWidthBreakpoint = useGetMuiBreakPointLabel();
    const breakPointsToHideUploadTextOn: Breakpoint[] = ["md", "sm", "xs"];
    const [vtCardRef, titleCutOffPoint] = useGetVTCardTitleCutOffPoint();

    const canvasControlsRef = useRef<any>();

    const dispatch = useAppDispatch();

    // ## SELECTORS AND MUTATIONS ##
    const { organisation, id: userId } = useAppSelector((state) => state.userReducer);
    const { mediaUploadPercentage, mediaUploadCompleted, mediaUploadError, mediaUploading } = useAppSelector(
        (state) => state.mediaUploadReducer,
    );

    const [updateAllImmersiveModels, { isLoading: updateAllModelsIsLoading }] = useUpdateAllImmersiveModelsMutation();
    const [createAllModels] = useCreateImmersiveModelsMutation();
    const [preSignedUrl, { data: uploadUrl, isLoading: preSignedUrlLoading }] = usePreSignedUrlMutation();

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const [settingPreview, setSettingPreview] = useState<boolean>(false);

    const [activeButton, setActiveButton] = useState(0);
    const [searchValue, setSearchValue] = useState<string>("");
    const [showSkeletonForCardById, setShowSkeletonForCardById] = useState<string>("");

    const [modelPreview, setModelPreview] = useState<string | null>(null);

    // search values
    const [filterValue, setFilterValue] = useState<string>("");
    const [nameBoxChecked, setNameBoxChecked] = useState<boolean>(true);
    const [tagsBoxChecked, setTagsBoxChecked] = useState<boolean>(false);

    // Upload states
    const [selectedModel, setSelectedModel] = useState<File | null>(null);
    const [selectedModelError, setSelectedModelError] = useState<boolean>(false);
    const [modelName, setModelName] = useState<string>("");
    const [modelId, setModelId] = useState<string>(uuid());

    const [highlight, setHighlight] = useState(false);
    const [changedItem, setChangedItem] = useState<string>("");

    useEffect(() => {
        if (!deleteLoading) {
            setChangedItem("");
        }
    }, [deleteLoading]);

    // ## USE EFFECTS ##

    useEffect(() => {
        if (mediaUploadCompleted && selectedModel && !settingPreview) {
            createAllModels({
                id: userId,
                media_id: modelId,
                media_type: "three_d_model",
                filetype: selectedModel.name.split(".")[1],
                name: modelName,
                description: "",
                date: Date.now(),
                tags: [],
                preview: false,
            });
            handleGetPreviewURL();
            setSettingPreview(true);
        }
    }, [mediaUploadCompleted]);

    useEffect(() => {
        if (uploadUrl && settingPreview) {
            handlePreviewUpload();
        }
    }, [uploadUrl]);

    // useEffect(() => {
    //     if (!updateLoading || !updateAllModelsIsLoading) {
    //         window.setTimeout(() => {
    //             setShowSkeletonForCardById("");
    //         }, 1000);
    //     }
    // }, [updateLoading, updateAllModelsIsLoading]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    function preventDefaults(e: React.DragEvent) {
        e.preventDefault();
        e.stopPropagation();
    }

    async function handleFileAdded(modelFile: File) {
        console.log("testing: ", modelFile);
        // TODO: in the future only allow certain model types
        if (["glb", "gltf"].includes(modelFile.name.split(".")[1])) {
            setSelectedModelError(false);
            setSelectedModel(modelFile);
            setModelName(modelFile.name.split(".")[0]);

            // Get presigned url to upload model
            await preSignedUrl({
                organisation,
                media_type: "three_d_model",
                media_id: `${modelId}/${modelId}`,
                filetype: modelFile.name.split(".")[1],
            });
        } else {
            setSelectedModelError(true);
        }
    }

    // Function that handle model added from dropping and dragging in
    function handleDrop(e: React.DragEvent) {
        preventDefaults(e);
        setHighlight(false);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleFileAdded(e.dataTransfer.files[0]);
        }
    }

    // Function that handle model added from file system
    function handleSelect(e: React.ChangeEvent<HTMLInputElement>) {
        setHighlight(false);

        if (e.target.files && e.target.files.length > 0) {
            handleFileAdded(e.target.files[0]);
        }
    }

    function handleModelUpload() {
        if (uploadUrl && selectedModel) {
            dispatch(
                uploadMedia({
                    media: selectedModel,
                    url: uploadUrl.url,
                    dispatch: dispatch,
                }),
            );
        }

        if (canvasControlsRef.current) {
            canvasControlsRef.current.takeScreenShot();
            handlePreviewUpload();
        }
    }

    async function handleGetPreviewURL() {
        // Get presigned url to upload image
        await preSignedUrl({
            organisation,
            media_type: "three_d_model",
            media_id: `${modelId}/${modelId}_preview`,
            filetype: "png",
        });
    }

    async function handlePreviewUpload() {
        if (modelPreview) {
            const file = await dataUrlToFile(modelPreview, "preview");

            if (uploadUrl && modelPreview) {
                dispatch(
                    uploadMedia({
                        media: file,
                        url: uploadUrl.url,
                        dispatch: dispatch,
                    }),
                );
            }
        }
    }

    async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
        const res: Response = await fetch(dataUrl);
        const blob: Blob = await res.blob();
        return new File([blob], fileName, { type: "image/png" });
    }

    function handleUploadCleanUp() {
        setSelectedModel(null);
        setModelId(uuid());
        setModelName("");
        dispatch(setMediaUploadCleanUp());
        setActiveButton(0);
    }

    return (
        <Grid container xs={12} alignItems={"flex-start"}>
            <Grid
                item
                container
                xs={12}
                spacing={1}
                sx={{
                    margin: 5,
                    marginTop: "110px",
                    // maxWidth: "100%",
                    marginLeft: leftDrawWidth / 8 + 5,
                    transition: "0.5s",
                }}
            >
                <Grid xs={12} container item sx={{ marginBottom: "25px" }} justifyContent={"space-between"}>
                    {activeButton === 0 && (
                        <Grid item xs={6}>
                            <VTTextField
                                value={filterValue}
                                onChange={(text: string) => setFilterValue(text)}
                                placeholder="Search Models"
                                endIcon={<SearchRounded />}
                                sx={{ width: "100%", maxWidth: "350px" }}
                            />
                        </Grid>
                    )}

                    <Grid container item xs={activeButton === 0 ? 6 : 12} justifyContent={"end"}>
                        <VTButton
                            onClick={() => {
                                if (activeButton === 0) {
                                    setActiveButton(1);
                                } else {
                                    setActiveButton(0);
                                }
                            }}
                            sx={{
                                maxWidth: "100%",
                                height: "60px",
                                borderBottom: "3px solid rgb(150, 28, 255)",
                            }}
                            type="underline"
                            endIcon={
                                activeButton === 0 ? (
                                    <UploadIcon sx={{ marginLeft: "10px" }} />
                                ) : (
                                    <KeyboardReturnIcon sx={{ marginLeft: "10px" }} />
                                )
                            }
                        >
                            {!breakPointsToHideUploadTextOn.includes(currentScreenWidthBreakpoint) && (
                                <Typography>{activeButton === 0 ? "Upload Model" : "Return"}</Typography>
                            )}
                        </VTButton>
                    </Grid>
                </Grid>
                <Grid item container xs={12}>
                    {activeButton === 0 && (
                        <Grid item container justifyContent="flex-start" alignItems="flex-start" spacing={1}>
                            {models && models.length === 0 ? (
                                <Grid item xs={12} textAlign={"center"} sx={{ marginTop: "50px" }}>
                                    <img src={NoScenarios} />
                                    <VTTypography sx={{ color: "grey", padding: "10px" }}>
                                        To get started, add first 3D model file (.glb and .gltf formats)
                                    </VTTypography>
                                </Grid>
                            ) : (
                                <>
                                    {loading ? (
                                        <Grid
                                            sx={{}}
                                            item
                                            container
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            spacing={1}
                                        >
                                            <Grid item sm={12} md={6} lg={4} xl={3} xs={12}>
                                                <VTCard
                                                    sx={{ maxWidth: "100%" }}
                                                    mediaType={{ imageUrl: "" }}
                                                    title={""}
                                                    loading={true}
                                                />
                                            </Grid>
                                            <Grid item sm={12} md={6} lg={4} xl={3} xs={12}>
                                                <VTCard
                                                    sx={{ maxWidth: "100%" }}
                                                    mediaType={{ imageUrl: "" }}
                                                    title={""}
                                                    loading={true}
                                                />
                                            </Grid>
                                            <Grid item sm={12} md={6} lg={4} xl={3} xs={12}>
                                                <VTCard
                                                    sx={{ maxWidth: "100%" }}
                                                    mediaType={{ imageUrl: "" }}
                                                    title={""}
                                                    loading={true}
                                                />
                                            </Grid>

                                            {(currentScreenWidthBreakpoint === "xl" ||
                                                currentScreenWidthBreakpoint === "md") && (
                                                <Grid item sm={12} md={6} lg={4} xl={3} xs={12}>
                                                    <VTCard
                                                        sx={{ maxWidth: "100%" }}
                                                        mediaType={{ imageUrl: "" }}
                                                        title={""}
                                                        loading={true}
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                    ) : (
                                        <>
                                            {models
                                                ?.filter((audio: IMediaItem) => {
                                                    return (
                                                        (nameBoxChecked &&
                                                            audio.name
                                                                ?.toLowerCase()
                                                                .includes(filterValue.toLocaleLowerCase())) ||
                                                        (tagsBoxChecked &&
                                                            audio.tags.filter((tag: string) => {
                                                                return tag
                                                                    .toLowerCase()
                                                                    .includes(filterValue.toLowerCase());
                                                            }).length > 0)
                                                    );
                                                })
                                                .sort((a: IMediaItem, b: IMediaItem) => {
                                                    return new Date(b.date).valueOf() - new Date(a.date).valueOf();
                                                })
                                                .map((model: IMediaItem, i: number) => (
                                                    <Grid item sm={12} md={6} lg={4} xl={3} xs={12} key={model.id}>
                                                        {(deleteLoading || updateAllModelsIsLoading) &&
                                                        changedItem === model.id ? (
                                                            <VTCard
                                                                sx={{ maxWidth: "100%", width: "100%" }}
                                                                mediaType={{ imageUrl: "" }}
                                                                title={""}
                                                                loading={true}
                                                            />
                                                        ) : (
                                                            <VTCard
                                                                ref={i === 0 ? vtCardRef : undefined}
                                                                titleCutOffPoint={titleCutOffPoint}
                                                                title={model.name ? model.name : "no name"}
                                                                sx={{ width: "100%", maxWidth: "none" }}
                                                                mediaType={{
                                                                    showPreview: true,
                                                                    modelUrl: `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/three_d_model/${model.id}/${model.id}.${model.filetype}`,
                                                                    preview: ``,
                                                                }}
                                                                // src={`${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/three_d_model/${model.id}.${model.filetype}`}
                                                                loading={showSkeletonForCardById === model.id}
                                                                onTitleEdited={(editedTitle: string) => {
                                                                    setChangedItem(model.id);
                                                                    updateAllImmersiveModels({
                                                                        id: model.id,
                                                                        name: editedTitle,
                                                                        media_type: EMedia.MODEL_3D,
                                                                    });
                                                                }}
                                                                menuOptions={{
                                                                    menuOptionsList: [
                                                                        {
                                                                            component: (
                                                                                <>
                                                                                    {organisation === "virtus-tech" && (
                                                                                        <VTButton
                                                                                            type={"list"}
                                                                                            startIcon={<DownloadIcon />}
                                                                                            onClick={() => {
                                                                                                const imageUrl = `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/three_d_model/${model.id}.${model.filetype}`;

                                                                                                const link =
                                                                                                    document.createElement(
                                                                                                        "a",
                                                                                                    );
                                                                                                link.href = imageUrl;

                                                                                                document.body.appendChild(
                                                                                                    link,
                                                                                                );
                                                                                                link.click();

                                                                                                document.body.removeChild(
                                                                                                    link,
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            Download
                                                                                        </VTButton>
                                                                                    )}
                                                                                </>
                                                                            ),
                                                                        },

                                                                        {
                                                                            component: (
                                                                                <VTButton
                                                                                    type={"list"}
                                                                                    startIcon={<DeleteIcon />}
                                                                                    disabled={false}
                                                                                    onClick={() => {
                                                                                        setChangedItem(model.id);
                                                                                        deleteMediaItem(
                                                                                            EMedia.MODEL_3D,
                                                                                            userId,
                                                                                            model.id,
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    Delete
                                                                                </VTButton>
                                                                            ),
                                                                        },
                                                                    ],
                                                                    menuPopOutZIndex: 100,
                                                                }}
                                                            />
                                                        )}
                                                    </Grid>
                                                ))}
                                        </>
                                    )}
                                </>
                            )}
                        </Grid>
                    )}

                    {activeButton === 1 && (
                        <Grid container justifyContent={"center"} alignContent={"center"}>
                            <div onDrop={handleDrop} onDragOver={preventDefaults} style={{ padding: "20px" }}>
                                {selectedModel ? (
                                    <Grid container>
                                        <Grid xs={12} sx={{ margin: "10px 5px" }}>
                                            <VTTextField
                                                value={modelName}
                                                onChange={(value) => setModelName(value)}
                                                disabled={mediaUploading || mediaUploadCompleted}
                                            />
                                        </Grid>
                                        <Grid xs={12} sx={{ margin: "10px 5px" }}>
                                            <Suspense fallback={null}>
                                                <VTCanvas
                                                    screenShot={(preview) => setModelPreview(preview)}
                                                    style={{ height: "250px", borderRadius: "15px" }}
                                                    ref={canvasControlsRef}
                                                >
                                                    <ambientLight />
                                                    <pointLight position={[1, 1, 1]} />

                                                    {selectedModel && (
                                                        <Model
                                                            url={URL.createObjectURL(selectedModel)}
                                                            cameraControlsRef={canvasControlsRef}
                                                        />
                                                    )}
                                                </VTCanvas>
                                            </Suspense>
                                        </Grid>
                                        {preSignedUrlLoading ? (
                                            <Grid
                                                xs={12}
                                                sx={{
                                                    margin: "10px 5px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <CircularProgress />
                                            </Grid>
                                        ) : (
                                            <>
                                                {mediaUploading || mediaUploadCompleted ? (
                                                    <>
                                                        {mediaUploadError ? (
                                                            <>
                                                                <Grid
                                                                    xs={12}
                                                                    sx={{
                                                                        margin: "10px 0",
                                                                        width: "100%",
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                        align="center"
                                                                    >
                                                                        Error Uploading Model
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid
                                                                    xs={12}
                                                                    sx={{
                                                                        margin: "10px 0",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                    }}
                                                                >
                                                                    <VTButton
                                                                        color={"secondary"}
                                                                        onClick={() => handleUploadCleanUp()}
                                                                    >
                                                                        Cancel
                                                                    </VTButton>
                                                                </Grid>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Grid
                                                                    xs={12}
                                                                    sx={{
                                                                        margin: "10px 0",
                                                                        width: "100%",
                                                                    }}
                                                                >
                                                                    <LinearProgress
                                                                        variant="determinate"
                                                                        value={mediaUploadPercentage}
                                                                    />
                                                                </Grid>
                                                                {mediaUploadCompleted && (
                                                                    <Grid
                                                                        xs={6}
                                                                        sx={{
                                                                            margin: "10px 0",
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            padding: "2px",
                                                                        }}
                                                                    >
                                                                        <VTButton
                                                                            color={"secondary"}
                                                                            fullWidth={true}
                                                                            onClick={() => {
                                                                                setSelectedModel(null);
                                                                                setModelId(uuid());
                                                                                setModelName("");
                                                                                dispatch(setMediaUploadCleanUp());
                                                                            }}
                                                                        >
                                                                            Upload Another
                                                                        </VTButton>
                                                                    </Grid>
                                                                )}
                                                                <Grid
                                                                    xs={mediaUploadCompleted ? 6 : 12}
                                                                    sx={{
                                                                        margin: "10px 0",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        padding: "2px",
                                                                    }}
                                                                >
                                                                    <VTButton
                                                                        color={
                                                                            mediaUploadCompleted
                                                                                ? "primary"
                                                                                : "secondary"
                                                                        }
                                                                        fullWidth={true}
                                                                        onClick={() => handleUploadCleanUp()}
                                                                    >
                                                                        {mediaUploadCompleted ? "Done" : "Cancel"}
                                                                    </VTButton>
                                                                </Grid>
                                                            </>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <Grid
                                                            xs={6}
                                                            sx={{
                                                                margin: "10px 0",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                padding: "2px",
                                                            }}
                                                        >
                                                            <VTButton
                                                                color={"secondary"}
                                                                onClick={() => setSelectedModel(null)}
                                                                fullWidth={true}
                                                            >
                                                                Cancel
                                                            </VTButton>
                                                        </Grid>
                                                        <Grid
                                                            xs={6}
                                                            sx={{
                                                                margin: "10px 0",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                padding: "2px",
                                                            }}
                                                        >
                                                            <VTButton
                                                                onClick={() => {
                                                                    if (modelName.length > 0) {
                                                                        handleModelUpload();
                                                                    } else {
                                                                        dispatch(
                                                                            setCurrentToast({
                                                                                id: "360 images",
                                                                                message:
                                                                                    "must enter a name for 360 image",
                                                                            }),
                                                                        );
                                                                    }
                                                                }}
                                                                fullWidth={true}
                                                            >
                                                                upload
                                                            </VTButton>
                                                        </Grid>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </Grid>
                                ) : (
                                    <Grid
                                        container
                                        //TODO: user this as an overlay
                                        onDragEnter={() => setHighlight(true)}
                                        onDragLeave={() => setHighlight(false)}
                                        sx={{
                                            border: highlight ? "3px dashed #7315ce" : "1px dashed #f7f7f7",
                                            borderRadius: "15px",
                                            width: "100%",
                                            height: "100%",
                                        }}
                                    >
                                        <Grid
                                            xs={12}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                margin: "50px 0px",
                                            }}
                                        >
                                            <img
                                                src={"/media/images/upload.svg"}
                                                alt="upload media"
                                                style={{ maxHeight: "200px" }}
                                            />
                                        </Grid>
                                        <Grid xs={12}>
                                            <Typography align="center">
                                                drop or choose model to upload (.glb and .gltf formats)
                                            </Typography>

                                            {selectedModelError && (
                                                <Typography align="center" sx={{ color: "red" }}>
                                                    file type not accepted
                                                </Typography>
                                            )}
                                        </Grid>
                                        <Grid
                                            xs={12}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                margin: "50px 0px",
                                            }}
                                        >
                                            <input
                                                accept="*/*"
                                                type="file"
                                                id="file"
                                                ref={inputFileRef}
                                                style={{ display: "none" }}
                                                onChange={(e) => handleSelect(e)}
                                            />
                                            <VTButton
                                                onClick={() => {
                                                    if (inputFileRef && inputFileRef.current) {
                                                        inputFileRef.current.click();
                                                    }
                                                }}
                                            >
                                                Choose File
                                            </VTButton>
                                        </Grid>
                                    </Grid>
                                )}
                            </div>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}
