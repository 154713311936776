//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { BrowserRouter, Routes, Route, useNavigate, useParams, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Error from "./pages/Error";
import ProtectedRoute from "../auth/ProtectedRoute";
import Creator from "./pages/Creator";
import {
    VTButton,
    VTLogo,
    VTMenu,
    VTNavBar,
    VTTextField,
    VTTypography,
    textCutOffAddElipsis,
} from "@virtus-tech-repository/virtus-tech-repository";
import {
    Grid,
    LinearProgress,
    MenuItem,
    Paper,
    Select,
    Switch,
    Tooltip,
    Typography,
    alpha,
    useTheme,
} from "@mui/material";
import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import { PersonRounded, LogoutRounded, SettingsRounded } from "@mui/icons-material";
import { setUserId, setUserOrg } from "../store/slices/user.slice";
import Media from "./pages/Media";
import { useEffect, useState } from "react";
import { useAppSelector } from "../store/hooks";
import "../App.css";
import { v4 as uuid } from "uuid";
import { setCurrentHotspot, setCurrentToast, setCurrentlyPreview } from "../store/slices/current.slice";
import ToastImage from "../assets/images/NoScenarioImage.svg";
import CloseRounded from "@mui/icons-material/CloseRounded";
import Logo from "../assets/images/Logo.svg";
import { useGetAllScenarioOverviewQuery } from "../services/scenario.service";
import TuneIcon from "@mui/icons-material/Tune";
import { TuneRounded, VisibilityOffRounded, VisibilityRounded } from "@mui/icons-material";
import ReactGA from "react-ga4";

export default function AppRoutes() {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const dispatch = useDispatch();

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##
    const { email: userEmail, organisation } = useAppSelector((state) => state.userReducer);
    const { currentPage, currentToast, currentlyPreview, currentHotspot } = useAppSelector(
        (state) => state.currentReducer,
    );
    const { id: userId } = useAppSelector((state) => state.userReducer);

    const [toastDisplayArray, setToastDisplayArray] = useState<
        {
            id: string;
            message?: string;
            time?: number;
            children?: any;
        }[]
    >([]);
    const [adminOverrideId, setAdminOverrideId] = useState<string>("");
    const [adminOverrideOrg, setAdminOverrideOrg] = useState<string>("");

    const { data, isLoading, error } = useGetAllScenarioOverviewQuery(userId);

    const [currentScenarioName, setCurrentScenarioName] = useState<string | undefined>();
    const [localCurrentlyPreview, setLocalCurrentlyPreview] = useState<boolean>(false);

    useEffect(() => {
        setLocalCurrentlyPreview(currentlyPreview);
    }, [currentlyPreview]);
    useEffect(() => {
        if (currentPage === "creator" && data) {
            let scenarioName = data?.filter((scenarioOverviewObj) => {
                return window.location.href.includes(scenarioOverviewObj.id);
            })[0].title;
            setCurrentScenarioName(scenarioName);
        }
    }, [currentPage, data]);

    const [displayUserEmailToolTip, setDisplayUserEmailToolTip] = useState<boolean>(true);

    const theme = useTheme();

    const iconStyles = {
        background: theme.palette.primary.main,
        borderRadius: "50%",
        padding: "4px",
        color: "#fff",
        "&:hover": {
            background: theme.palette.primary.dark,
        },
    };

    useEffect(() => {
        if (currentToast) {
            if (!toastDisplayArray.find((element: any) => element.id === currentToast.id)) {
                setToastDisplayArray([
                    ...toastDisplayArray,
                    {
                        message: currentToast.message,
                        time: currentToast.time,
                        children: currentToast.component,
                        id: currentToast.id,
                    },
                ]);

                const timer = setTimeout(() => {
                    dispatch(setCurrentToast(undefined));
                }, 100);
                return () => clearTimeout(timer);
            } else {
                dispatch(setCurrentToast(undefined));
            }
        }
    }, [currentToast]);

    // useEffect(() => {
    //     if (userId) {
    //         ReactGA.initialize([
    //             {
    //                 trackingId: "G-5YHHTR9NKB",

    //             },
    //         ]);
    //     }
    // }, [userId]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    async function handleLogout() {
        await Auth.signOut()
            .then((data) => {
                dispatch(setUserId(""));
            })
            .catch((err) => {
                console.log(err);
            });
    }

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    return (
        <>
            {toastDisplayArray &&
                toastDisplayArray.map((element: any, index: number) => {
                    return (
                        <Paper
                            key={element.id}
                            sx={{
                                width: "250px",
                                height: "160px",
                                position: "absolute",
                                top: `${100 + index * 185}px`,
                                right: currentToast && currentToast.id === element.id ? "-270px" : "25px",
                                zIndex: 10000,
                                transition: " 1s",
                            }}
                        >
                            <Grid
                                container
                                justifyContent={"center"}
                                alignContent={"center"}
                                direction={"column"}
                                spacing={"3px"}
                            >
                                <Grid item container xs={12} justifyContent={"center"}>
                                    <Grid item>
                                        {element.component ? (
                                            element.component
                                        ) : (
                                            <img src={ToastImage} height={"100px"} />
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <VTTypography align="center">{element.message}</VTTypography>
                                </Grid>
                            </Grid>

                            <VTButton
                                type="icon"
                                sx={{
                                    position: "absolute",
                                    top: "-12px",
                                    left: "-12px",
                                    backgroundColor: "#3a3b3d",
                                    height: "25px",
                                    width: "25px",
                                    ":hover": {
                                        backgroundColor: "#6a6b6d",
                                    },
                                }}
                                onClick={() => {
                                    setToastDisplayArray(
                                        toastDisplayArray.filter((toastElement: any) => toastElement.id !== element.id),
                                    );
                                }}
                            >
                                <CloseRounded sx={{ fontSize: "0.8em" }} />
                            </VTButton>
                        </Paper>
                    );
                })}

            {/*-----------------------------------------------------------------------------------------*/}
            {/* Nav Bar */}
            <VTNavBar
                sx={{
                    zIndex: 100,
                    ...(currentPage === "creator" && { background: "linear-gradient(to left, #0ab5a7,#8e25fa)" }),
                    position: "fixed",
                }}
                //------------------------------------------------------------------------------------------
                // Primary nav bar area; logo
                primaryArea={
                    currentPage === "login" ? (
                        <Grid container xs={12} sx={{ alignItems: "center", justifyContent: "center" }}>
                            <Grid item>
                                {/* <VTLogo
                                    // className="App-logo"
                                    style={{ height: 60 }}
                                    organization={"app_defaults"}
                                    variant={"small"}
                                    onClick={() => (window.location.pathname = "/")}
                                /> */}
                                <img
                                    src={Logo}
                                    height={45}
                                    style={{ margin: "-5px" }}
                                    onClick={() => (window.location.pathname = "/")}
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container xs={12}>
                            <Grid sx={{ display: "flex" }} item>
                                {/* <VTLogo
                                    style={{ height: 50, marginBottom: "-10px", marginTop: "-5px", cursor: "pointer" }}
                                    organization={"app_defaults"}
                                    variant={"small"}
                                    onClick={() => (window.location.pathname = "/")}
                                /> */}
                                <img
                                    style={{ cursor: "pointer" }}
                                    src={Logo}
                                    height={45}
                                    // style={{ margin: "-5px", marginLeft: "10px" }}
                                    onClick={() => (window.location.pathname = "/")}
                                />
                            </Grid>
                            <Typography
                                sx={{ marginLeft: "25px", cursor: "pointer" }}
                                onClick={() => (window.location.pathname = "/")}
                                variant="h4"
                            >
                                Creator
                            </Typography>
                        </Grid>
                    )
                }
                //------------------------------------------------------------------------------------------
                // Alternative nav bar area; settings
                altArea={
                    currentPage !== "login" && (
                        <Grid item sx={{ alignItems: "center" }} container xs={12} justifyContent={"flex-end"}>
                            {/*----------------------------------------------------------------------------------*/}
                            {currentScenarioName && (
                                <Tooltip title={currentScenarioName} placement="left">
                                    <Typography variant="h6" sx={{ marginRight: "15px" }}>
                                        {textCutOffAddElipsis(currentScenarioName, 18)}
                                    </Typography>
                                </Tooltip>
                            )}
                            {currentPage === "creator" && (
                                <Tooltip title={"toggle preview mode"}>
                                    <Grid direction={"row"} sx={{ marginRight: "10px" }}>
                                        <VTButton
                                            onClick={() => {
                                                dispatch(setCurrentlyPreview(!currentlyPreview));
                                                if (currentHotspot.currentHotspot) {
                                                    dispatch(
                                                        setCurrentHotspot({ currentHotspot: undefined, open: false }),
                                                    );
                                                }
                                            }}
                                            type="icon"
                                            sx={{
                                                background: currentlyPreview
                                                    ? alpha(theme.palette.primary.dark, 0.5)
                                                    : alpha(theme.palette.primary.dark, 0.2),
                                                paddingRight: currentlyPreview ? "8px" : "40px",
                                                paddingLeft: currentlyPreview ? "40px" : "8px",
                                                transition: "0.5s",
                                                "&:hover": {
                                                    background: currentlyPreview
                                                        ? alpha(theme.palette.primary.dark, 0.6)
                                                        : alpha(theme.palette.primary.dark, 0.3),
                                                },
                                            }}
                                        >
                                            {currentlyPreview ? (
                                                <VisibilityRounded sx={iconStyles} />
                                            ) : (
                                                <VisibilityOffRounded sx={iconStyles} />
                                            )}
                                        </VTButton>
                                    </Grid>
                                </Tooltip>
                            )}
                            {organisation === "virtus-tech" && (
                                <Grid item>
                                    <VTMenu
                                        type={"icon"}
                                        toolTipOptions={{
                                            toolTipText: "admin",
                                            toolTipPlacement: "bottom",
                                            // alwaysOpen: true
                                        }}
                                        menuOptions={[
                                            {
                                                component: (
                                                    <VTTextField
                                                        placeholder="user Id"
                                                        value={adminOverrideId}
                                                        onChange={(e) => setAdminOverrideId(e)}
                                                        sx={{ padding: "5px" }}
                                                    />
                                                ),
                                            },
                                            {
                                                component: (
                                                    <Select
                                                        value={adminOverrideOrg}
                                                        label="org"
                                                        onChange={(e: any) => setAdminOverrideOrg(e.target.value)}
                                                        sx={{ width: "96%", margin: "5px" }}
                                                    >
                                                        <MenuItem value={"virtus-tech"}>VIRTUS_TECH</MenuItem>
                                                        <MenuItem value={"cardiff-uni"}>CARDIFF_UNI</MenuItem>
                                                        <MenuItem value={"new-collage"}>NEW_COLLAGE</MenuItem>
                                                        <MenuItem value={"wrexham-uni"}>WREXHAM_UNI</MenuItem>
                                                        <MenuItem value={"cardiff-met-uni"}>CARDIFF_MET_UNI</MenuItem>
                                                        <MenuItem value={"zurich-uk"}>ZURICH_UK</MenuItem>
                                                        <MenuItem value={"jisc"}> JISC</MenuItem>
                                                        <MenuItem value={"cdc"}> CDC</MenuItem>
                                                        <MenuItem value={"hack"}> HACK</MenuItem>
                                                    </Select>
                                                ),
                                            },
                                            {
                                                component: (
                                                    <VTButton
                                                        onClick={() => {
                                                            dispatch(setUserId(adminOverrideId));
                                                            dispatch(setUserOrg(adminOverrideOrg));
                                                        }}
                                                        sx={{ margin: "5px", width: "96%" }}
                                                    >
                                                        Submit
                                                    </VTButton>
                                                ),
                                            },
                                        ]}
                                        sx={{ marginRight: "10px" }}
                                    >
                                        <SettingsRounded />
                                    </VTMenu>
                                </Grid>
                            )}
                            {/* User Settings */}
                            <Grid item>
                                {/* <Tooltip title={userEmail} placement="left"> */}

                                <VTMenu
                                    onClick={() => {
                                        // console.log("OPEN");
                                        setDisplayUserEmailToolTip(false);
                                    }}
                                    onCloseFunction={() => {
                                        // console.log("CLOSING");
                                        setDisplayUserEmailToolTip(true);
                                    }}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        width: "40px",
                                        height: "40px",
                                        top: "3px",
                                        zIndex: 3,
                                        // "& .MuiPaper-root": {
                                        //     border: "3px solid yellow",
                                        //     zIndex: 10000000
                                        // }
                                    }}
                                    type="icon"
                                    color="primary"
                                    toolTipOptions={{
                                        toolTipText: userEmail,
                                        toolTipPlacement: displayUserEmailToolTip ? "bottom" : "left",
                                        // alwaysOpen: true
                                    }}
                                    menuOptions={[
                                        {
                                            component: (
                                                <VTButton
                                                    type={"list"}
                                                    startIcon={<LogoutRounded />}
                                                    onClick={() => {
                                                        handleLogout();
                                                    }}
                                                >
                                                    Logout
                                                </VTButton>
                                            ),
                                        },
                                    ]}
                                    popOutMenuZIndex={101}
                                >
                                    <PersonRounded />
                                </VTMenu>
                                {/* </Tooltip> */}
                            </Grid>
                        </Grid>
                    )
                }
            />
            <Grid
                sx={{
                    position: "relative",
                    width: "100%",
                    height: "100vh",
                    overflow: "hidden",
                }}
            >
                <BrowserRouter>
                    <Routes>
                        {/*------------------------------------------------------------------------------------*/}
                        {/* Default route to the home page */}
                        <Route
                            path="/"
                            element={
                                <ProtectedRoute>
                                    <Home />
                                </ProtectedRoute>
                            }
                        />

                        {/*------------------------------------------------------------------------------------*/}
                        {/* Route to the Templates page */}
                        <Route
                            path="/Templates"
                            element={
                                <ProtectedRoute>
                                    <Home />
                                </ProtectedRoute>
                            }
                        />

                        {/*------------------------------------------------------------------------------------*/}
                        {/* Route to the Marketplace page */}
                        <Route
                            path="/Marketplace"
                            element={
                                <ProtectedRoute>
                                    <Home />
                                </ProtectedRoute>
                            }
                        />

                        {/*------------------------------------------------------------------------------------*/}
                        {/* Route to the Plugins page */}
                        <Route
                            path="/Plugins"
                            element={
                                <ProtectedRoute>
                                    <Home />
                                </ProtectedRoute>
                            }
                        />

                        {/*------------------------------------------------------------------------------------*/}
                        {/* Route to the Creator page, with scenario id and scene id as params */}
                        <Route
                            path="/Media/:mediaPage"
                            element={
                                <ProtectedRoute>
                                    <Media />
                                </ProtectedRoute>
                            }
                        />

                        {/*------------------------------------------------------------------------------------*/}
                        {/* Route to the Creator page, with scenario id and scene id as params */}
                        <Route
                            path="/Creator/:scenarioId"
                            element={
                                <ProtectedRoute>
                                    <Creator />
                                </ProtectedRoute>
                            }
                        >
                            <Route
                                path="/Creator/:scenarioId/:sceneId"
                                element={
                                    <ProtectedRoute>
                                        <Creator />
                                    </ProtectedRoute>
                                }
                            ></Route>
                        </Route>

                        {/*------------------------------------------------------------------------------------*/}
                        {/* 404 page error handling */}
                        <Route path="*" element={<Error />} />
                    </Routes>
                </BrowserRouter>
            </Grid>
        </>
    );
}
