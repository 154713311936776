import { useHelper } from "@react-three/drei";
import { useRef } from "react";
import { CameraHelper } from "three";

export default function CameraWireFrameHelper() {
    const camera = useRef<THREE.PerspectiveCamera>();

    // @ts-ignore
    useHelper(camera, CameraHelper);

    return (
        <perspectiveCamera
            makeDefault={false}
            position={[0, 1.5, 4.5]}
            near={0.3}
            far={0.7}
            // @ts-ignore
            ref={camera}
        >
            <meshBasicMaterial />
        </perspectiveCamera>
    );
}
